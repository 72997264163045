// This is a template of a fbConfig.js file.

const firebaseConfig = {
  apiKey: "AIzaSyBvB2xmUARXbCUA6kugwCa2B5i_-9pbKv8",
  authDomain: "wca-kisy.firebaseapp.com",
  databaseURL: "https://wca-kisy.firebaseio.com",
  projectId: "wca-kisy",
  storageBucket: "wca-kisy.appspot.com",
  messagingSenderId: "8403682762",
  appId: "1:8403682762:web:ce137faa9ff291f2c4bb33",
  measurementId: "G-NT9ZWBP53V"
};

export default firebaseConfig;

/**
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2019 Werbeagentur Christian Aichner
 */
